import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import {
  grantCustomAudiencesSuccess,
  grantCustomAudiencesFailed,
} from '../actions';

export default function* handleGrantCustomAudiences({ payload }) {
  try {
    const data = yield call(
      backendAPI.grantCustomAudiences,
      payload.facebookAdsAccount,
    );

    yield put(grantCustomAudiencesSuccess(data));
  } catch (e) {
    yield put(grantCustomAudiencesFailed(e));
  }
}
