import { backendAPI } from 'api';
import { END, eventChannel } from 'redux-saga';
import { call, fork, put, take } from 'redux-saga/effects';
import { fbacIRI, projectIRI } from 'common/resource-wrappers';
import {
  uploadMediaSuccess,
  uploadMediaFailed,
  uploadProgress,
} from '../actions';

function setupUpload(formData) {
  let emit;

  const channel = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });

  const onUploadProgress = ({ loaded, total }) => {
    const percentCompleted = Math.round((loaded * 100) / total);

    emit({ name: formData.get('file').name, progress: percentCompleted });

    if (percentCompleted === 100) {
      emit(END); // notify channel has been closed
    }
  };

  const uploadPromise = backendAPI.upload(formData, onUploadProgress);
  return [uploadPromise, channel];
}

function* watchProgress(channel) {
  while (true) {
    const { name, progress } = yield take(channel);
    yield put(uploadProgress(name, progress));
  }
}

export default function* handleUploadMedia({ payload }) {
  try {
    const { project, files, facebookAdsAccount } = payload;

    const data = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      const form = new FormData();

      form.append('project', projectIRI(project.uuid));
      form.append('file', file);
      form.append('facebookAdsAccount', fbacIRI(facebookAdsAccount));

      const [uploadPromise, channel] = yield call(setupUpload, form);
      yield fork(watchProgress, channel);

      data.push(yield call(() => uploadPromise));
    }

    yield put(uploadMediaSuccess(data, project.uuid));
  } catch (e) {
    yield put(uploadMediaFailed(e));
  }
}
