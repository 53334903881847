/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import { LOAD_SESSION } from 'containers/App/constants';
import projectsReducer from 'containers/App/projects/reducer';
import mediaReducer from 'containers/App/media/reducer';
import appReducer from 'containers/App/reducer';
import alertsReducer from 'containers/Alerts/reducer';
import modalsReducer from 'containers/Modals/reducer';
import jobsReducer from 'containers/Jobs/reducer';
import sessionsReducer from 'containers/App/sessions/reducer';
import completionsReducer from 'containers/App/completions/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const combinedReducers = combineReducers({
    router: connectRouter(history),
    projects: projectsReducer,
    media: mediaReducer,
    app: appReducer,
    alerts: alertsReducer,
    modals: modalsReducer,
    jobs: jobsReducer,
    sessions: sessionsReducer,
    completions: completionsReducer,
    ...injectedReducers,
  });

  const rootReducer = (state, action) => {
    if (action.type === LOAD_SESSION) {
      if (action.payload.state) {
        return { ...state, ...action.payload.state };
      }

      // reducers return the initial state if called with undefined
      return combinedReducers(undefined, action);
    }

    return combinedReducers(state, action);
  };

  return rootReducer;
}
