import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import {
  launchBudgetOptimizationSuccess,
  launchBudgetOptimizationFailed,
} from '../actions';

const jobIRI = job => `/api/jobs/${job.uuid}`;

export default function* handleOptimizationLaunch({ payload }) {
  try {
    const { job, cboLookalikeCode } = payload;

    const data = yield call(backendAPI.campaignOptimization, {
      job: jobIRI(job),
      project: job.request?.project,
      facebookAdsAccount: job.request?.facebookAdsAccount,
      cboLookalikeCode,
    });

    yield put(launchBudgetOptimizationSuccess(data));
  } catch (e) {
    yield put(launchBudgetOptimizationFailed(e));
  }
}
