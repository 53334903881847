import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import {
  getAccountAudiencesSuccess,
  getAccountAudiencesFailed,
} from '../actions';

export default function* handleGetAudiences({ payload }) {
  try {
    const data = yield call(backendAPI.facebookAdsAccountAudiences, payload.id);

    yield put(getAccountAudiencesSuccess(data));
  } catch (e) {
    yield put(getAccountAudiencesFailed(e));
  }
}
