import { createSelector } from 'reselect';
import {
  FORM,
  PRIMARY,
  HEADLINE,
  DESCRIPTION,
  MEDIA,
  BIDDING_CONTROL,
  CTA,
  FB_PAGE,
  NO_BUTTON,
} from 'containers/AdsPilot/constants';
import {
  PROJECT_SELECTED,
  LAUNCH_ADS,
  LAUNCH_ADS_SUCCESS,
  LAUNCH_ADS_FAILED,
  USER_DATA_UPDATED,
  CLEAR_PROJECT_DATA,
  DAILY_BUDGET_UPDATED,
  FETCH_PROJECTS_SUCCESS,
  FORM_SELECTED,
  LAUNCH_GOOGLE_ADS,
  LAUNCH_GOOGLE_ADS_FAILED,
  LAUNCH_GOOGLE_ADS_SUCCESS,
  AUDIENCE_SIZE_UPDATED,
  GET_FACEBOOK_COMPLETIONS_SUCCESS,
  AD_ACCOUNT_SELECTED,
  GET_FACEBOOK_COMPLETIONS,
  GET_FACEBOOK_COMPLETIONS_FAILED,
  IMPORT_JOB_DATA,
  SET_PRESET,
  ITERATION_UPDATED,
  CAMPAIGN_SETTINGS_UPDATED,
} from 'containers/App/constants';
import { THUMBNAIL_UPDATED } from 'containers/Modals/constants';
import { get } from 'lodash';
import { replaceAt } from 'utils/replaceAt';
import { PRESETS } from 'containers/AdsPilot/presets';
import { isProduction } from 'utils/environment';

const AdSet = ({
  [PRIMARY]: primary = '',
  [HEADLINE]: headline = '',
  [DESCRIPTION]: description = '',
  [FB_PAGE]: facebookPage = '',
  [BIDDING_CONTROL]: bidding = 0,
  [CTA]: cta = NO_BUTTON,
} = {}) => ({
  [PRIMARY]: primary,
  [HEADLINE]: headline,
  [DESCRIPTION]: description,
  [BIDDING_CONTROL]: bidding,
  [CTA]: cta,
  [MEDIA]: [],
  [FB_PAGE]: facebookPage,
});

const generate = ({ project = {} } = {}) => ({
  [FORM.FB]: [
    AdSet({ facebookPage: null }),
    AdSet({ facebookPage: null }),
    AdSet({ facebookPage: null }),
  ],
  [FORM.GOOGLE]: {
    keywords: [],
    headlineOne: 'Now Trending on Kickstarter',
    headlineTwo: project.name || '',
    headlineThree: '',
    descriptionOne: project.description || '',
    descriptionTwo: 'Special Kickstarter pricing ends soon. Don’t miss out.',
  },
});

const generatePreset = ({
  preset = 'default',
  pages: [firstPage, secondPage, thirdPage] = [],
}) => {
  const [first, second, third] = PRESETS[preset];

  return [
    AdSet({ ...first, [FB_PAGE]: firstPage }),
    AdSet({ ...second, [FB_PAGE]: secondPage }),
    AdSet({ ...third, [FB_PAGE]: thirdPage }),
  ];
};

const DEFAULT_AUDIENCE_SIZE = 4;

export const initialState = {
  selectedProject: '',
  loadingAds: false,
  userData: {},
  dailyBudgets: {},
  iterations: {},
  audienceSizes: {},
  thumbnails: {},
  form: FORM.FB,
  selectedFBAdsAccount: '',
  loadingCompletions: {},
  campaignSettings: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AD_ACCOUNT_SELECTED:
      return { ...state, selectedFBAdsAccount: payload.id };

    case FETCH_PROJECTS_SUCCESS: {
      const projects = payload.data;

      const data = projects.reduce(
        (userData, project) => ({
          ...userData,
          [project.uuid]: userData[project.uuid] || generate({ project }),
        }),
        state.userData,
      );

      const audienceSizes = projects.reduce(
        (sizes, project) => ({
          ...sizes,
          [project.uuid]: sizes[project.uuid] || DEFAULT_AUDIENCE_SIZE,
        }),
        state.audienceSizes,
      );

      return { ...state, userData: data, audienceSizes };
    }

    case PROJECT_SELECTED:
      return {
        ...state,
        selectedProject: payload.projectId,
      };

    case FORM_SELECTED:
      return {
        ...state,
        form: payload.type,
      };

    case LAUNCH_GOOGLE_ADS:
    case LAUNCH_ADS:
      return { ...state, loadingAds: true };

    case LAUNCH_GOOGLE_ADS_SUCCESS:
    case LAUNCH_GOOGLE_ADS_FAILED:
    case LAUNCH_ADS_SUCCESS:
    case LAUNCH_ADS_FAILED:
      return { ...state, loadingAds: false };

    case USER_DATA_UPDATED:
      return {
        ...state,
        userData: {
          ...state.userData,
          [state.selectedProject]: {
            ...state.userData[state.selectedProject],
            [state.form]: payload.data,
          },
        },
      };

    case GET_FACEBOOK_COMPLETIONS: {
      const { index } = payload;

      return {
        ...state,
        loadingCompletions: { ...state.loadingCompletions, [index]: true },
      };
    }

    case GET_FACEBOOK_COMPLETIONS_FAILED: {
      const { index } = payload;

      return {
        ...state,
        loadingCompletions: { ...state.loadingCompletions, [index]: false },
      };
    }

    case GET_FACEBOOK_COMPLETIONS_SUCCESS: {
      const { data, project, index } = payload;

      const projectData = state.userData[project.uuid][FORM.FB];

      const fbData = replaceAt(projectData, index, {
        ...projectData[index],
        [PRIMARY]: data[PRIMARY],
        [HEADLINE]: data[HEADLINE],
        [DESCRIPTION]: data[DESCRIPTION],
      });

      return {
        ...state,
        loadingCompletions: { ...state.loadingCompletions, [index]: false },
        userData: {
          ...state.userData,
          [project.uuid]: {
            ...state.userData[project.uuid],
            [FORM.FB]: fbData,
          },
        },
      };
    }

    case SET_PRESET: {
      return {
        ...state,
        userData: {
          ...state.userData,
          [state.selectedProject]: {
            ...state.userData[state.selectedProject],
            [FORM.FB]: generatePreset({ pages: payload.pages }),
          },
        },
        audienceSizes: {
          ...state.audienceSizes,
          [state.selectedProject]: DEFAULT_AUDIENCE_SIZE,
        },
      };
    }

    case DAILY_BUDGET_UPDATED: {
      return {
        ...state,
        dailyBudgets: {
          ...state.dailyBudgets,
          [state.selectedProject]: payload.data,
        },
      };
    }

    case ITERATION_UPDATED: {
      return {
        ...state,
        iterations: {
          ...state.iterations,
          [state.selectedProject]: payload.data,
        },
      };
    }

    case CAMPAIGN_SETTINGS_UPDATED: {
      return {
        ...state,
        campaignSettings: {
          ...state.campaignSettings,
          [state.selectedProject]: payload.data,
        },
      };
    }

    case AUDIENCE_SIZE_UPDATED: {
      return {
        ...state,
        audienceSizes: {
          ...state.audienceSizes,
          [state.selectedProject]: payload.data,
        },
      };
    }

    case CLEAR_PROJECT_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          [payload.projectId]: generate(),
        },
      };

    case THUMBNAIL_UPDATED: {
      const { adSetIndex, video, thumbnail } = payload;
      return {
        ...state,
        thumbnails: {
          ...state.thumbnails,
          [video]: {
            ...state.thumbnails[video],
            [adSetIndex]: thumbnail,
          },
        },
      };
    }

    case IMPORT_JOB_DATA: {
      const { messageType, request } = payload.data;
      const [form] = messageType.split('.');
      // strip project key
      const {
        project,
        schedule,
        mode,
        lookalikeCode,
        cboLookalikeCode,
        campaignStatusActive,
        adSetBudget,
        ...importData
      } = request;

      const prevCampaignSettings = get(
        state,
        ['campaignSettings', payload.project],
        {},
      );

      const copyData =
        form === FORM.FB
          ? importData.creatives.map(creative => ({ ...creative, media: [] }))
          : importData;

      return {
        ...state,
        selectedProject: payload.project,
        form,
        userData: {
          ...state.userData,
          [payload.project]: {
            ...state.userData[payload.project],
            [form]: copyData,
          },
        },
        campaignSettings: {
          ...state.campaignSettings,
          [payload.project]: {
            ...prevCampaignSettings,
            schedule,
            mode,
            lookalikeCode,
            cboLookalikeCode,
            campaignStatusActive,
            adSetBudget,
          },
        },
      };
    }

    default:
      return state;
  }
};

export const getApp = state => state.app;
export const getSelectedProjectId = state => getApp(state).selectedProject;
export const isLoadingAds = state => getApp(state).loadingAds;
export const isLoadingCompletions = state => index =>
  getApp(state).loadingCompletions[index];
export const getFormType = state => getApp(state).form;
export const getSelectedFBAdsAccount = state =>
  getApp(state).selectedFBAdsAccount;

export const getDailyBudgets = state => getApp(state).dailyBudgets;
export const getConfiguredDailyBudget = createSelector(
  getSelectedProjectId,
  getDailyBudgets,
  (projectId, budgets) => budgets[projectId] || '',
);

export const getIterations = state => getApp(state).iterations || {};
export const getConfiguredIteration = createSelector(
  getSelectedProjectId,
  getIterations,
  (projectId, iterations) => iterations[projectId] || 1,
);

export const getAudienceSize = state => getApp(state).audienceSizes;
export const getConfiguredAudienceSize = createSelector(
  getSelectedProjectId,
  getAudienceSize,
  (projectId, audienceSizes) => audienceSizes[projectId] || '',
);

export const getUserData = state => getApp(state).userData;
export const getSelectedProjectUserData = createSelector(
  getSelectedProjectId,
  getFormType,
  getUserData,
  (projectId, form, userData) => get(userData, `${projectId}.${form}`),
);

export const getThumbnails = state => getApp(state).thumbnails;
export const getSelectedThumbnailByAdSetAndVideo = createSelector(
  getThumbnails,
  thumbnails => (index, video) =>
    thumbnails && thumbnails[video] && thumbnails[video][index],
);

const SETTING_DEFAULTS = {
  [FORM.FB]: {
    adSetBudget: false,
    schedule: false,
    campaignStatusActive: isProduction(),
    mode: 'STANDARD',
  },

  [FORM.GOOGLE]: {
    topCountries: true,
    campaignStatusActive: isProduction(),
    kickstarterOnlySearch: true,
  },
};

export const getCampaignSettings = state =>
  getApp(state).campaignSettings || {};
export const getConfiguredCampaignSettings = createSelector(
  getSelectedProjectId,
  getCampaignSettings,
  getFormType,
  (projectId, campaignSettings, form) =>
    campaignSettings[projectId] || SETTING_DEFAULTS[form],
);
