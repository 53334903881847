import { backendAPI } from 'api';
import { call, put, select } from 'redux-saga/effects';
import {
  getFacebookCompletionsSuccess,
  getFacebookCompletionsFailed,
  removeCompletionBuffer,
  addCompletionBuffer,
} from '../actions';
import { getBufferCompletion } from '../completions/reducer';

const projectIRI = project => `/api/projects/${project.uuid}`;

export default function* handleGetCompletions({ payload }) {
  const { project, index } = payload;

  const success = data =>
    getFacebookCompletionsSuccess({
      data,
      project,
      index,
    });

  try {
    const bufferByProject = yield select(getBufferCompletion);
    const buffer = bufferByProject(project.uuid);
    const hasBufferData = buffer && buffer[0];

    if (hasBufferData) {
      yield put(success(buffer[0]));
      yield put(removeCompletionBuffer(project.uuid));
    }

    const data = yield call(backendAPI.completions, projectIRI(project));

    if (hasBufferData) {
      yield put(addCompletionBuffer(project.uuid, data));
    } else {
      yield put(success(data));
    }
  } catch (e) {
    yield put(getFacebookCompletionsFailed(e, index));
  }
}
