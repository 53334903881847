import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth0 } from '@auth0/auth0-react';
import {
  formSelected,
  projectSelected,
  updateProject,
} from 'containers/App/actions';
import { getProjectOptions } from 'containers/App/projects/reducer';
import { getFormType, getSelectedProjectId } from 'containers/App/reducer';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import LogoutButton from 'containers/Auth/LogoutButton';
import LoginButton from 'containers/Auth/LoginButton';
import { common } from '@mui/material/colors';
import { FORM } from 'containers/AdsPilot/constants';
import { get } from 'lodash';
import { SearchableDropdown } from './Dropdown';
import { FacebookButton, GoogleButton, RefreshButton } from './Buttons';
import MenuPopover from './MenuPopover';

export const TOOLBAR_HEIGHT = 70;

const useStyles = makeStyles(theme => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#3FE075',
  },
  toolbar: {
    marginTop: theme.spacing(1),
    height: TOOLBAR_HEIGHT,
  },
  grow: {
    flexGrow: 1,
  },
  dropdown: {
    width: 'auto',
    minWidth: '25%',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  },
  divider: {
    margin: theme.spacing(1),
    color: 'white',
    borderColor: 'none',
    height: theme.spacing(2.5),
    alignSelf: 'center',
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
  },
  nav: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  navLink: {
    padding: '4px 12px',
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        borderBottom: `1px solid ${theme.palette.common.white}`,
      },
    },
  },
  logBtn: {
    padding: '4px 12px',
    color: theme.palette.common.black,
  },
}));

const UI = {
  TITLE: 'Ads Pilot',
  PROJECT: 'Project',
  SETTINGS: 'Settings',
  JOBS: 'Jobs',
  PROJECTS: 'Projects',
};

const navigateTo = to => location => {
  const [start, , id] = location.pathname.split('/');
  return [start, to, id].join('/');
};

const Options = ({ withDivider }) => {
  const classes = useStyles();

  return (
    <>
      <Link
        component={RouterLink}
        variant="button"
        color="inherit"
        to={navigateTo('projects')}
        underline="none"
        className={classes.navLink}
      >
        {UI.PROJECTS}
      </Link>
      {withDivider && (
        <Divider className={classes.divider} orientation="vertical" flexItem />
      )}
      <Link
        component={RouterLink}
        variant="button"
        color="inherit"
        to={navigateTo('jobs')}
        underline="none"
        className={classes.navLink}
      >
        {UI.JOBS}
      </Link>
      {withDivider && (
        <Divider className={classes.divider} orientation="vertical" flexItem />
      )}
      <Link
        component={RouterLink}
        variant="button"
        color="inherit"
        to={navigateTo('settings')}
        underline="none"
        className={classes.navLink}
      >
        {UI.SETTINGS}
      </Link>
      {withDivider && (
        <Divider className={classes.divider} orientation="vertical" flexItem />
      )}
      <LogoutButton className={classes.logBtn} />
    </>
  );
};

Options.propTypes = {
  withDivider: PropTypes.bool,
};

const NavOptions = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return isDesktop ? (
    <Options withDivider={isDesktop} />
  ) : (
    <MenuPopover className={classes.popover}>
      <Options />
    </MenuPopover>
  );
};

export const NavBar = ({
  project,
  projects,
  formType,
  onProjectSelect,
  onRefresh,
  onFormSelect,
}) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const hasProject = projects.some(p => p.value === id);

    if (!isAuthenticated || projects.length === 0) {
      return;
    }

    const selectedId = hasProject ? id : get(projects, ['0', 'value']);

    onProjectSelect(selectedId);

    const [start, page] = window.location.pathname.split('/');

    if (page && Object.values(FORM).includes(page)) {
      onFormSelect(page);
    }

    if (selectedId && page) {
      history.push({
        pathname: [start, page, selectedId].join('/'),
      });
    }
  }, [isAuthenticated, id, projects]);

  const onProjectChange = (event, value) => {
    onProjectSelect(value.value, value.externalId);

    const [start, page = FORM.FB] = window.location.pathname.split('/');

    const newPage = page === 'projects' ? formType : page;

    history.push({
      pathname: [start, newPage, value.value].join('/'),
    });
  };

  const onFormChange = event => {
    const { form: selectedForm } = event.currentTarget.dataset;

    const [start] = window.location.pathname.split('/');

    history.push({
      pathname: [start, selectedForm, project || id].join('/'),
    });

    onFormSelect(selectedForm);
  };

  const isSelected = type => formType === type;

  const selectedProject = useMemo(
    () => projects.find(option => option.value === project),
    [projects, project],
  );

  return (
    <AppBar position="static" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.toolbarTitle}
        >
          <Link component={RouterLink} color="inherit" to="/" underline="none">
            {UI.TITLE}
          </Link>
        </Typography>
        {isAuthenticated && (
          <>
            <SearchableDropdown
              className={classes.dropdown}
              id="project-select"
              label={UI.PROJECT}
              options={projects}
              value={selectedProject || ''}
              onChange={onProjectChange}
            />
            <RefreshButton
              disabled={!project}
              onClick={() => onRefresh(project)}
            />
            <FacebookButton
              data-form={FORM.FB}
              size={isSelected(FORM.FB) ? 'large' : 'medium'}
              sx={{ color: common.white }}
              onClick={onFormChange}
            />
            <GoogleButton
              data-form={FORM.GOOGLE}
              size={isSelected(FORM.GOOGLE) ? 'large' : 'medium'}
              sx={{ color: common.white }}
              onClick={onFormChange}
            />
          </>
        )}
        <div className={classes.grow} />
        <nav className={classes.nav}>
          {isAuthenticated ? (
            <NavOptions />
          ) : (
            <LoginButton className={classes.logBtn} />
          )}
        </nav>
      </Toolbar>
    </AppBar>
  );
};

NavBar.propTypes = {
  project: PropTypes.string,
  formType: PropTypes.string,
  projects: PropTypes.array.isRequired,
  onProjectSelect: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onFormSelect: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  projects: getProjectOptions(state),
  project: getSelectedProjectId(state),
  formType: getFormType(state),
});

const mapDispatchToProps = {
  onProjectSelect: projectSelected,
  onRefresh: updateProject,
  onFormSelect: formSelected,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBar);
