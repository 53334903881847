import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import { fbacIRI } from 'common/resource-wrappers';
import { grantPermissionsSuccess, grantPermissionsFailed } from '../actions';

export default function* handleGrantPermissions({ payload }) {
  try {
    const data = yield call(
      backendAPI.grantPermissions,
      fbacIRI(payload.facebookAdsAccount),
    );

    yield put(grantPermissionsSuccess(data));
  } catch (e) {
    yield put(grantPermissionsFailed(e));
  }
}
